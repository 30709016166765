/* COLOR PALETTE #0A1045, AFDF2C, 00C2D1, B08EA2, FAC9B8, FCEFF9 */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  /* background: rgba(34, 34, 34, 0.589);
  background: #b08ea2ea;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.212); */
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: 0.3s all ease-in-out;
}

.navscroll {
  background: #0a1045c9;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.212);
}

.hlane-logo {
  text-decoration: none;
  width: 10rem;
  margin: 0 1rem;
  transition: 0.2s all ease-in-out;
}

.nav-link-container {
  position: relative;
  display: flex;
  align-items: center;
}

.nav-link {
  margin: 0 1.8rem;
  text-decoration: none;
  color: rgb(175, 223, 44);
  font-size: 1.25rem;
  font-family: abril-display, serif;
  font-weight: 400;
  font-style: normal;
  opacity: 0.7;
  transition: 0.3s all ease-in-out;
}

.nav-link:hover {
  opacity: 1;
  color: rgb(203, 255, 61);
}

.active {
  color: rgb(203, 255, 61);
  opacity: 1;
}

.telephone-container {
  justify-self: end;
  margin: 0 1rem;
}

.fa-mobile-alt {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.telephone-num {
  text-decoration: none;
  font-size: 1.1rem;
  font-family: abril-display, serif;
  font-weight: 400;
  font-style: normal;
  color: rgb(175, 223, 44);
  opacity: 0.7;
  transition: 0.3s all ease-in-out;
}

.telephone-num:hover {
  opacity: 1;
}

.hamburger-menu {
  justify-self: end;
  margin: 0 1rem;
  display: none;
  cursor: pointer;
}

.hamburger {
  color: #afdf2c;
  font-size: 2rem;
}

.reponsive-nav-menu {
  background: #0a1045;
  display: grid;
  padding: 0;
  grid-column: 1/3;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  width: 100%;
}

.nav-link-res {
  width: 100%;
  margin: 0 auto;
  padding: 0.8rem 0;
  text-decoration: none;
  color: rgb(175, 223, 44);
  font-size: 1.5rem;
  font-family: abril-display, serif;
  font-weight: 400;
  font-style: normal;
  opacity: 0.7;
  transition: 0.3s all ease-in-out;
}

.nav-p {
  text-align: center;
}

.nav-link-res:hover {
  background-color: #afdf2c;
  color: #0a1045;
}

.nav-res-hide {
  display: none;
}

.hidenavondesk {
  display: none;
}

@media only screen and (max-width: 920px) {
  .nav-link {
    display: none;
    align-items: center;
  }
  .nav-tele {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }
}

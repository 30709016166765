.about-main {
  margin: 0 0 21.75rem 0;
  height: 100%;
  width: 100%;
  background: #fff;
}

.aboutmain-section-one {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0rem 0 0 0;
  /* justify-items: center; */
}

.about-heading-one {
  font-size: 2rem;
  font-weight: 500;
  color: rgb(77, 100, 110);
}

.about-text {
  margin: 8rem 0;
  justify-self: right;
  align-self: center;
}

.about-main-paras {
  width: 550px;
  color: rgb(77, 100, 110);
  line-height: 2rem;
  margin: 0;
  text-align: justify;
  transition: 0.3s all ease-in-out;
}

.image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.right-right {
  padding: 0rem;
  justify-self: end;
  width: 400px;
  height: 100%;
  background: url("https://images.unsplash.com/photo-1547499417-29204c97a299?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80")
    center center/cover no-repeat;
}

.rrbgclr {
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.87);
}

.abouthan {
  width: 50%;
  position: fixed;
  right: 0rem;
  bottom: 0;
  transition: 0.3s all ease-in-out;
}

.stickthree {
  position: absolute;
  bottom: 0rem;
}

@media only screen and (max-width: 1140px) {
  .about-text {
    margin: 8rem 2rem;
    justify-self: left;
  }
  .about-main-paras {
    width: 450px;
  }
}

@media only screen and (max-width: 1025px) {
  .about-main-paras {
    width: 400px;
  }
}

@media only screen and (max-width: 975px) {
  .abouthan {
    width: 500px;
    right: -15%;
  }
}

@media only screen and (max-width: 890px) {
  .aboutmain-section-one {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    background: #fff;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0rem 0 0 0;
    /* justify-items: center; */
  }

  .about-text {
    margin: 8rem 2rem;
    justify-self: center;
    width: 80%;
  }

  .about-heading-one {
    font-size: 2rem;
    font-weight: 500;

    color: rgb(77, 100, 110);
  }

  .about-main-paras {
    width: 100%;
    margin: 0 auto;
  }

  .image-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .right-right {
    padding: 0rem;
    justify-self: end;
    width: 100%;
    height: 250px;
    grid-column: 1/3;
    background: url("https://images.unsplash.com/photo-1547499417-29204c97a299?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80")
      center center/cover no-repeat;
  }
  .abouthan {
    width: 425px;
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    transition: 0.3s all ease-in-out;
  }
}

@media only screen and (max-width: 820px) {
  .about-main {
    margin: 0 0 33.5rem 0;
  }
}

@media only screen and (max-width: 630px) {
  .about-main {
    margin: 0 0 16.6rem 0;
  }

  .abouthan {
    width: 335px;
    position: absolute !important;
    right: 0rem;
    bottom: 0rem;
    transition: 0.3s all ease-in-out;
  }
}

@media only screen and (max-width: 500px) {
  .about-main {
    margin: 0 0 17.75rem 0;
  }
  .right-right {
    padding: 0rem;
    justify-self: end;
    width: 100%;
    height: 350px;
    grid-column: 1/3;
    background: url("https://images.unsplash.com/photo-1547499417-29204c97a299?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1868&q=80")
      center center/cover no-repeat;
  }
}

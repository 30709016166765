.home-main {
  width: 100%;
  position: relative;
  padding: 1rem 0 0rem 0;
  margin: 95vh 0 21.75rem 0;
  z-index: 1;
  background: #fff;
}

@media only screen and (max-width: 821px) {
  .home-main {
    width: 100%;
    position: relative;
    padding: 1rem 0 0rem 0;
    margin: 95vh 0 33.5rem 0;
    z-index: 1;
    background: #fff;
  }
}

@media only screen and (max-width: 631px) {
  .home-main {
    width: 153%;
    position: relative;
    padding: 1rem 0 0rem 0;
    margin: 95vh 0 16.65rem 0;
    z-index: 1;
    background: #fff;
  }
}

.home-main-header {
  margin: 5rem auto 3rem auto;
  width: 600px;
  width: 40%;
  font-size: 2rem;
  text-align: center;
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: rgb(49, 63, 70);
}

@media only screen and (max-width: 950px) {
  .home-main-header {
    width: 55%;
  }
}

@media only screen and (max-width: 640px) {
  .home-main-header {
    width: 85%;
  }
}

.hmh-hr {
  width: 73%;
  padding: 0.9px 0;
  border: none;
  background: rgb(126, 151, 163);
}

.tester {
  width: 50%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 4rem auto;
  text-align: center;
  color: rgb(49, 63, 70);
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 950px) {
  .tester {
    width: 80%;
  }
}

.hmainsecuno-flex {
  position: relative;
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 850px) {
  .hmainsecuno-flex {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

.hover-div-one {
  position: absolute;
  color: #fff;
  width: 600px;
  height: 300px;
  transition: 0.3s all ease-in-out;
}

.hover-div-one:hover {
  background: rgba(0, 0, 0, 0.39);
  cursor: pointer;
  animation: slideover;
  animation-duration: 0.5s;
}

.hover-div-one:hover::after {
  background: rgba(0, 0, 0, 0.185);
  content: "Minneapolis Condos Directory";
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  font-size: 2rem;
  transition: 0.3s all ease-in-out;
}

.minCondo {
  width: 600px;
  height: 300px;
  transition: 0.3s all ease-in-out;
  object-fit: cover;
}

.hover-div-two {
  position: absolute;
  color: #fff;
  width: 600px;
  height: 300px;
  transition: 0.3s all ease-in-out;
}

.hover-div-two:hover {
  background: rgba(0, 0, 0, 0.39);
  cursor: pointer;
  animation: slideover;
  animation-duration: 0.5s;
}

.hover-div-two:hover::after {
  background: rgba(0, 0, 0, 0.185);
  content: "St. Paul Condos Directory";
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  font-size: 2rem;
  transition: 0.3s all ease-in-out;
}

@keyframes slideover {
  0% {
    /* transform: translateX(0); */
    opacity: 0;
  }
  100% {
    width: 600px;
    opacity: 1;
  }
}

.stpCondo {
  width: 600px;
  height: 300px;
  transition: 0.3s all ease-in-out;
  object-fit: cover;
}

.hideImage {
  visibility: hidden;
}

.condoscroll {
  animation-name: imageUp;
  animation-duration: 1s;
}

@keyframes imageUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.parascroll {
  animation-name: appearingPara;
  animation-duration: 1.5s;
}

@keyframes appearingPara {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1280px) {
  .hover-div-one {
    width: 500px;
    height: 270px;
  }

  @keyframes slideover {
    0% {
      /* transform: translateX(0); */
      opacity: 0;
    }
    100% {
      width: 500px;
      opacity: 1;
    }
  }

  .minCondo {
    width: 500px;
    height: 270px;
  }

  .hover-div-two {
    width: 500px;
    height: 270px;
  }

  .stpCondo {
    width: 500px;
    height: 270px;
  }
}

@media only screen and (max-width: 1050px) {
  .hover-div-one {
    width: 400px;
  }

  @keyframes slideover {
    0% {
      /* transform: translateX(0); */
      opacity: 0;
    }
    100% {
      width: 400px;
      opacity: 1;
    }
  }

  .minCondo {
    width: 400px;
  }

  .hover-div-two {
    width: 400px;
  }

  .stpCondo {
    width: 400px;
  }
}

@media only screen and (max-width: 850px) {
  .hover-div-one {
    width: 600px;
    height: 270px;
  }

  @keyframes slideover {
    0% {
      /* transform: translateX(0); */
      opacity: 0;
    }
    100% {
      width: 600px;
      opacity: 1;
    }
  }

  .minCondo {
    width: 600px;
    height: 270px;
  }

  .hover-div-two {
    width: 600px;
    height: 270px;
  }

  .stpCondo {
    width: 600px;
    height: 270px;
  }
}

@media only screen and (max-width: 650px) {
  .hover-div-one {
    width: 500px;
    height: 270px;
  }

  @keyframes slideover {
    0% {
      /* transform: translateX(0); */
      opacity: 0;
    }
    100% {
      width: 500px;
      opacity: 1;
    }
  }

  .minCondo {
    width: 500px;
    height: 270px;
  }

  .hover-div-two {
    width: 500px;
    height: 270px;
  }

  .stpCondo {
    width: 500px;
    height: 270px;
  }
}

@media only screen and (max-width: 550px) {
  .hover-div-one {
    width: 400px;
    height: 270px;
  }

  @keyframes slideover {
    0% {
      /* transform: translateX(0); */
      opacity: 0;
    }
    100% {
      width: 400px;
      opacity: 1;
    }
  }

  .minCondo {
    width: 400px;
    height: 270px;
  }

  .hover-div-two {
    width: 400px;
    height: 270px;
  }

  .stpCondo {
    width: 400px;
    height: 270px;
  }
}

@media only screen and (max-width: 500px) {
  .hover-div-one {
    width: 330px;
    height: 270px;
  }

  @keyframes slideover {
    0% {
      /* transform: translateX(0); */
      opacity: 0;
    }
    100% {
      width: 330px;
      opacity: 1;
    }
  }

  .minCondo {
    width: 330px;
    height: 270px;
  }

  .hover-div-two {
    width: 330px;
    height: 270px;
  }

  .stpCondo {
    width: 330px;
    height: 270px;
  }
}

/* SECTION TWO */

.services {
  width: 600px;
  margin: 10rem auto 5rem auto;
  text-align: right;
  color: rgb(49, 63, 70);
  transition: 0.3s all ease-in-out;
}

.services-header {
  margin: 0;
  font-size: 1.8rem;
  transition: 0.3s all ease-in-out;
}

.services-paragraph {
  margin: 0;
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 700px) {
  .services {
    width: 500px;
  }
}

@media only screen and (max-width: 580px) {
  .services-header {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 540px) {
  .services {
    width: 400px;
  }

  .services-header {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 500px) {
  .services {
    width: 90%;
    margin: 5rem auto 5rem auto;
    text-align: right;
    color: rgb(49, 63, 70);
  }

  .services-header {
    margin: 0;
    font-size: 1.4rem;
  }

  .services-paragraph {
    margin: 0;
  }
}

.icon-container-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  width: 80%;
}

@media only screen and (max-width: 900px) {
  .icon-container-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 570px) {
  .icon-container-grid {
    grid-template-columns: 1fr;
  }
}

.icon-container-grid > div {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin: 0 0 2rem 0;
  width: 80%;
}

.six-icon {
  width: 5rem;
}

.hideIcon {
  visibility: hidden;
}

.iOnescroll {
  animation-name: iconUp;
  animation-duration: 1s;
  animation-delay: 0.1s;
}

.iTwoscroll {
  animation-name: iconUp;
  animation-duration: 1s;
  animation-delay: 0.2s;
}

.iThreescroll {
  animation-name: iconUp;
  animation-duration: 1s;
  animation-delay: 0.3s;
}

.iFourscroll {
  animation-name: iconUp;
  animation-duration: 1s;
  animation-delay: 0.4s;
}

.iFivescroll {
  animation-name: iconUp;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.iSixscroll {
  animation-name: iconUp;
  animation-duration: 1s;
  animation-delay: 0.6s;
}

@keyframes iconUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hidePara {
  visibility: hidden;
}

.pscroll {
  animation-name: paraUp;
  animation-duration: 2s;
  animation-delay: 1s;
}

@keyframes paraUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* SECTION THREE */
.bolded-text {
  font-weight: 700;
}

.home-second-header {
  margin: 5rem auto 3rem auto;
  width: 80%;
  font-size: 2rem;
  text-align: center;
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: rgb(49, 63, 70);
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 990px) {
  .home-second-header {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 750px) {
  .home-second-header {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 660px) {
  .home-second-header {
    width: 85%;
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 660px) {
  .home-second-header {
    width: 85%;
    font-size: 1.1rem;
  }
}

.hmain-section-tres {
  width: 100%;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr;
  justify-items: center;
  transition: 0.3s all ease-in-out;
}

.hmain-section-three {
  color: rgb(87, 100, 107);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
  margin: 0 auto 5rem auto;
  padding: 1rem;
  justify-self: center;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
}

.GSC {
  grid-column: 1/3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #fff3f0;
  transition: 0.3s all ease-in-out;
}

.graph-h3 {
  font-size: 2rem;
  margin: 32px 0 0 0;
}

.graph-para {
  margin: 0 0 24px 0;
  font-size: 1.5rem;
}

.graph-h3 {
  font-size: 2rem;
  text-align: center;
}

.graph-para {
  font-size: 1.5rem;
  text-align: center;
}

.Pie {
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  transition: 0.3s all ease-in-out;
}

svg:not(:root) {
  overflow: visible;
  position: relative;
  width: 100%;
  margin: 0 auto;
  transition: 0.3s all ease-in-out;
}

svg {
  display: grid;
  grid-template-columns: 1fr;
}

.total-container {
  /* background: #fff; */
  width: 100%;
  position: relative;
  top: -12.5rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: #ce6300;
}

.costs-container {
  display: flex;
  width: 100%;
  text-align: center;
  position: relative;
  top: -4rem;
  align-content: center;
  justify-content: space-between;
}

.m-box {
  margin: 0 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  transition: 0.3s all ease-in-out;
}

.annIns-permonth {
  font-size: 1.95rem;
  font-weight: bold;
  color: #0a1045;
  transition: 0.3s all ease-in-out;
}

.tax-permonth {
  font-size: 1.95rem;
  font-weight: bold;
  color: #00c2d1;
  transition: 0.3s all ease-in-out;
}

.mortgage-permonth {
  font-size: 1.95rem;
  font-weight: bold;
  color: #afdf2c;
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 700px) {
  .m-box {
    font-size: 1.25rem;
  }
}

/* FORM */
.FSC {
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff3f0;
  border-left: 1px solid rgba(175, 29, 29, 0.137);
  transition: 0.3s all ease-in-out;
}

.enter-details-para {
  margin: 1rem 2rem;
  text-align: justify;
  justify-self: center;
  transition: 0.3s all ease-in-out;
}

.MF {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;
  background: #fff2ee;
  height: 450px;
  overflow-y: scroll;
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 1090px) {
  hmain-section-three {
    color: rgb(87, 100, 107);
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0 auto 5rem auto;
    padding: 1rem;
    justify-self: center;
    border-radius: 5px;
  }
  .GSC {
    width: 100%;
    grid-column: 1/4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #fff3f0;
  }
  .FSC {
    grid-column: 1/4;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff3f0;
    border: none;
    padding: 0 0 2rem 0;
  }
  .MF {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    width: 100%;
    background: #fff2ee;
    height: 300px;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 730px) {
  .MF {
    grid-template-columns: 1fr;
  }
}

.mortgage-form::-webkit-scrollbar {
  width: 0.5rem;
}
.mortgage-form::-webkit-scrollbar-track {
  background: #f8fffe;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.226);
}
.mortgage-form::-webkit-scrollbar-thumb {
  background-color: rgb(173, 199, 211);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

label {
  margin: 0.75rem 0;
  color: rgb(65, 78, 80);
  font-size: 1.1rem;
}

input {
  display: block;
  width: 250px;
  margin: 0.5rem 0;
  border: none;
  color: rgb(48, 55, 95);
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.096);
}

select {
  display: block;
  font-size: 1rem;
  padding: 0.25rem 0;
  color: rgb(86, 92, 97);
  width: 260px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.range-width {
  width: 260px;
  box-shadow: 0 0 0 black;
}

@media only screen and (max-width: 501px) {
  .hmain-section-three {
    width: 100%;
    padding: 0;
  }
  .costs-container {
    display: block;
  }

  .m-box {
    font-size: 1.5rem;
  }

  .annIns-permonth {
    font-size: 2.5rem;
  }

  .tax-permonth {
    font-size: 2.5rem;
  }

  .mortgage-permonth {
    font-size: 2.5rem;
  }

  .enter-details-para {
    margin: 0rem 2rem 1rem 2rem;
  }
}

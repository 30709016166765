.minneapolis-header {
  margin: 0;
}
.minnbg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 55vh;
  margin: 0;
  object-fit: cover;
  z-index: 1;
}

.minnbgscroll {
  display: none;
}

.minnheader-bg-cover {
  position: absolute;
  background: rgba(29, 26, 26, 0.322);
  background: linear-gradient(
    180deg,
    rgba(29, 26, 26, 0.473),
    rgba(29, 26, 26, 0.137)
  );
  top: 0;
  margin: 0;
  width: 100%;
  height: 55vh;
  z-index: 1;
}

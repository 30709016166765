/* COLOR PALETTE #0A1045, AFDF2C, 00C2D1, B08EA2, FAC9B8, FCEFF9 */

/* .footer-top {
  background: #0a1045;
  height: 0rem;
} */

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  background: url("https://images.unsplash.com/photo-1547044479-59ce6c0a784a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80")
    center center/cover no-repeat;
  box-shadow: 0px -2px 15px rgba(139, 156, 116, 0.473);
}

.footer-top-layer {
  background: rgba(139, 156, 116, 0.575);
  height: 260px;
}

.footer-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.footer-left-grid {
  margin: 3rem 0;
}

.MARL {
  width: 14rem;
  margin: 0.5rem 0;
}

.NAoRL {
  display: block;
  width: 14rem;
  margin: 0.5rem 0;
}

.footer-center-grid {
  justify-self: center;
  display: grid;
  align-items: center;
}

.hlane-footer-logo {
  width: 14rem;
  background: #0c145c67;
  border: #0c145c solid 0.3rem;
  padding: 0.2rem 0.75rem;
  border-radius: 7px;
  margin: 1rem 0 0 0;
}

.social-links-container {
  justify-self: center;
  align-self: start;
  text-align: right;
}

.social-links {
  font-size: 2.2rem;
  margin: 0 0.5rem;
  color: #0c145c;
  transition: 0.3s all ease-in-out;
}

.social-links:hover {
  opacity: 0.7;
  color: #232d8d;
}

.footer-right-grid {
  display: grid;
  /* align-items: center; */
  justify-items: center;
}

.verve-container {
  text-align: center;
  margin: 3rem 0 0 0;
  /* background: #233f8dea; */
  padding: 0.5rem;
  border-radius: 5px;
}

.partnered {
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.verve-logo {
  width: 12rem;
}

.creator-section {
  width: 100%;
  background: #46772f;
  background: #6c7e55;
  background: #465334;
}

.creator-para {
  padding: 2rem 1rem;
  margin: 0;
  color: #65b83f;
  color: #a4ce6e;
}

.website-creator {
  color: #fff;
  font-size: 1.1rem;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

.website-creator:hover {
  text-decoration: underline;
  opacity: 0.7;
}

@media only screen and (max-width: 821px) {
  .footer-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}

@media only screen and (max-width: 631px) {
  .footer-top-layer {
    background: rgba(139, 156, 116, 0.575);
    height: 196px;
  }

  .footer-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }

  .MARL {
    width: 5rem;
    margin: 0.5rem 0;
  }

  .NAoRL {
    display: block;
    width: 5rem;
    margin: 0.5rem 0;
  }

  .hlane-footer-logo {
    width: 12rem;
    background: #0c145c67;
    border: #0c145c solid 0.3rem;
    padding: 0.2rem 0.75rem;
    border-radius: 7px;
    margin: 0 auto;
  }

  .verve-logo {
    width: 5rem;
  }

  .partnered {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 631px) {
  .footer-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }

  .MARL {
    width: 5rem;
    margin: 0.5rem 0;
  }

  .NAoRL {
    display: block;
    width: 5rem;
    margin: 0.5rem 0;
  }

  .footer-center-grid {
    margin: 1rem 0;
  }

  .hlane-footer-logo {
    width: 6rem;
    background: #0c145c67;
    border: #0c145c solid 0.3rem;
    padding: 0.1rem 0.5rem;
    border-radius: 7px;
  }

  .verve-logo {
    width: 5rem;
  }

  .partnered {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.6rem;
  }

  .social-links {
    font-size: 1.6rem;
    margin: 0 0.5rem;
    color: #0c145c;
    transition: 0.3s all ease-in-out;
  }
}

.homeheader-bg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 95vh;
  background: url("https://images.unsplash.com/photo-1505873242700-f289a29e1e0f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1955&q=80")
    no-repeat;
  background-position-y: -200px;
  overflow-y: hidden;
  opacity: 0.99;
}

.headerscroll {
  /* position: relative; */
  display: none;
}

.header-cover-layer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 95vh;
  margin: 0 auto;
  background: #6341554b;
}

.header-text-container {
  font-style: normal;
  position: relative;
  z-index: 999;
  top: -1rem;
  color: #fceff9;
  text-align: center;
  width: 600px;
  margin: 0 auto;
}

.header-one {
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  line-height: 5rem;
  margin: 0;
  padding: 0;
  text-shadow: 0 0 15px black;
}

.header-two {
  margin: 0;
  padding: 0;
  font-family: hero-new, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 2.5rem;
  text-shadow: 0 0 15px black;
}

.header-para {
  margin: 0;
  font-size: 2.4rem;
  font-family: novecento-sans, sans-serif;
  font-weight: 100;
  font-style: normal;
  text-shadow: 0 0 15px black;
}

.cutimagehannah {
  position: absolute;
  bottom: -5px;
  width: 650px;
  width: 25%;
  left: 2rem;
  max-width: 500px;
  transition: 0.3s all ease-in-out;
}

@media only screen and (max-width: 1185px) {
  .cutimagehannah {
    left: -4rem;
    width: 530px;
  }
}

@media only screen and (max-width: 959px) {
  .cutimagehannah {
    left: -4rem;
    width: 55%;
  }
}

@media only screen and (max-width: 820px) {
  .header-one {
    font-size: 3.4rem;
    line-height: 4.5rem;
  }

  .header-two {
    font-size: 2.2rem;
  }

  .header-para {
    font-size: 2.1rem;
  }
}

@media only screen and (max-width: 630px) {
  .header-text-container {
    width: 100%;
    margin: 0 auto;
  }

  .header-one {
    font-size: 2.7rem;
  }

  .header-two {
    font-size: 1.6rem;
  }

  .header-para {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 499px) {
  .cutimagehannah {
    left: 2rem;
    width: 75%;
  }
}
